import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react'; 
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';  
 
function Priority_user() { 
  const [data, setData] = useState([]); 
  const [dataemp, setDataemp] = useState([]); 
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  useEffect(()=>{
    var sid = sessionStorage.getItem("siteid");
    $('#siteid').val(sid); 
    var snameses = sessionStorage.getItem("sitename"); 
    $('#snameses').val(snameses); 
    var siteid = sid;
    console.log(siteid);
    async function fetchData() {
      try {
        $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/agency_sel_2.php",data:{siteid:siteid},success(data){ 
          var jsonData1 = JSON.parse(data);  
          setData(jsonData1);
      }});
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData(); 
}, []);
const fetchpri = async () =>{
  for (let i = 0; i < data.length; i++) { 
    var agencycode = $('#agencycode'+[i]).val();   
    var site = $('#site'+[i]).val();
    $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/ageprio_sel.php",data:{site:site,agencycode:agencycode},success(data){ 
      try {
        // Attempt to parse the response as JSON
        var jsonData5 = JSON.parse(data);
        // Assuming the response is an array of objects
        // Access the first object's priority value
        $('#prio'+i).val(jsonData5[0].priority);
    } catch (error) {
        // Handle parsing error
        console.error("Error parsing JSON:", error);
    } 
  }});
  }} 
  fetchpri();  
const send_data = (event) =>
{  
    for (let i = 0; i < data.length; i++) { 
        var agencycode = $('#agencycode'+[i]).val();  
   var agency = $('#agency'+[i]).val();
   var site = $('#site'+[i]).val();
   var prio = $('#prio'+[i]).val();
   $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/age_prio.php",data:{ agency:agency,agencycode:agencycode,site:site,prio:prio},success(datanew){
        if(datanew){
          swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
            window.location.reload();
          });
          } 
          else{
            swal({title:"Error!",text:"One Or More Record Already Exists",icon:"error",buttons:false,timer:2000}).then(()=>{
              window.location.reload();
            });
          } 
        }}); 
    }
    
    
} 
const handleKeyPress = (event, index, field) => {
  const { keyCode } = event;
  if (keyCode === 37 || keyCode === 38 || keyCode === 39 || keyCode === 40) {
      const nextIndex = keyCode === 37 || keyCode === 38 ? index - 1 : index + 1;
      const nextField = document.getElementById(`${field}${nextIndex}`);
      if (nextField) {
          nextField.focus();
          event.preventDefault();
      }
  }
} 
const logout=()=>{
  setIsLoggedIn(false);
  sessionStorage.removeItem("logstatus");
  window.location.reload();
}
 const handleActivity = () => {
  // Reset the logout timer on user activity
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
  const timer = setTimeout(logout, 180 * 60 * 1000); // Reset timer to 3 minutes
  setLogoutTimer(timer);
};
return ( 
<div><Container style={{ color: "#565656", padding: '0', backgroundColor: 'white', maxWidth: '80rem', borderRadius: '15px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)' }} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
   <br />
   <h2 align="center">Attendance Setup</h2>
   <hr />
   <Row align="left" style={{ padding: '10px' }}>
   <Col md={12}>
   <Form.Control type="text" id="snameses" readOnly />
       <Form.Control id="siteid" type="text" readOnly hidden/>  </Col>
   </Row>   <Row>
    <Col md={12}><center>
    <Table striped bordered hover responsive>
     <thead> 
       <tr>
         <th>Contractor Code</th>
         <th>Contractor Name</th>
         <th>Priority</th>   
       </tr>
     </thead>  
     <tbody>
      {data.map((item, index) =>  ( <tr key={index}> 
      <td>
      <Form.Control type="text" id={`agencycode${index}`} name={`agencycode${index}`} value={`${item.agency_code}`} readOnly />
           <Form.Control type="text" id={`site${index}`} name={`site${index}`} value={`${item.site}`} readOnly hidden/> 
            </td>
            <td><Form.Control type="text" id={`agency${index}`} name={`agency${index}`} value={`${item.contractor_name}`} readOnly /> </td>
           <td><Form.Control type="text" id={`prio${index}`} name={`prio${index}`}  onKeyDown={(e) => handleKeyPress(e, index, 'prio')}/> </td> 
             </tr> ))}  
              </tbody> 
 </Table> 
    </center></Col></Row> 
 <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '100%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}  onClick={send_data}>Save</Button>
 </Container>
 </div>
);
}

export default Priority_user;