import Table from 'react-bootstrap/Table';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs'; 
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';

function Worker_attendance_sheet_user() { 
  const [snameses, setSnameses] = useState('');
  const [datamain, setDatamain] = useState([]);
  const [datasite, setDatasite] = useState([]);
  const [datadate, setDatadate] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  const check = async()=>{
    $.ajax({
      type: "POST",
      url: "https://erp.smpinfra.in/smp/admin/checkdate.php",
      success: function(data) {
        try {
          var Data1 = JSON.parse(data);  
          setDatadate(Data1); 
        } catch(error) {
          console.error("Error parsing JSON:");
        }
      } 
    });
    $.ajax({
      type: "POST",
      url: "https://erp.smpinfra.in/smp/admin/check.php",
      success: function(data) {
        try {
          var Data2 = JSON.parse(data);  
          setDatamain(Data2); 
         // console.log(Data2);
        } catch(error) {
          console.error("Error parsing JSON:");
        }
      } 
    });
    $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/site_sel.php",success(data){
		  var jsonData = JSON.parse(data);
      setDatasite(jsonData);
  }});
  }
  useEffect(()=>{
    check();
		var sid = sessionStorage.getItem("siteid");
		  $('#siteid').val(sid);   
      var snameses = sessionStorage.getItem("username"); 
      $('#snameses').val(snameses); 
      setSnameses(snameses);
  },[]); 
  const hide = (event) =>{
    document.getElementById('siteid').style.display="";
    document.getElementById('datecreated1').style.display="";
    var report1 = $('#report').val(); 
    
    if(report1=="contractorattendance"){ 
     document.getElementById('siteid').style.display="";
     document.getElementById('datecreated1').style.display="";
    }
    else if(report1=="staffattendance"){ 
     document.getElementById('siteid').style.display="";
     document.getElementById('datecreated1').style.display="";
    } 
    else{}
 }
  
 const redirect = (event) =>{
   var report = $('#report').val();
   var siteid = $('#siteid').val();
   var datecreated = $('#datecreated').val();
   var datecreated1 = $('#datecreated1').val(); 
   
if(datecreated==null || datecreated==""){
   swal({title:"Error!",text:"Date Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
     $('#datecreated').focus();
   });
 }else{
   if(report=="contractorattendance"){ 
     if(siteid==null || siteid==""){ 
       swal({title:"Error!",text:"Project Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
         $('#siteid').focus();
       });
     }
     else if(siteid=="all"){
      window.location.href="https://erp.smpinfra.in/smp/admin/siteall_con_attendance.php?datecreated="+datecreated+"&datecreated1="+datecreated1;
       setTimeout(() => {
         window.location.reload();
       }, 300000);
     }
     else{
       window.location.href="https://erp.smpinfra.in/smp/admin/worker_attendance.php?siteid="+siteid+"&datecreated="+datecreated+"&datecreated1="+datecreated1;
       setTimeout(() => {
         window.location.reload();
       }, 300000); 
       //emp_attendance
     }}
    else if(report=="staffattendance"){ 
     if(siteid==null || siteid==""){ 
       swal({title:"Error!",text:"Project Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
         $('#siteid').focus();
       });
     }
     else if(siteid=="all"){
       window.location.href="https://erp.smpinfra.in/smp/admin/empall_attendance.php?datecreated="+datecreated+"&datecreated1="+datecreated1;
        setTimeout(() => {
          window.location.reload();
        }, 300000);
      }
     else{
       window.location.href="https://erp.smpinfra.in/smp/admin/emp_attendance.php?siteid="+siteid+"&datecreated="+datecreated+"&datecreated1="+datecreated1;
       setTimeout(() => {
         window.location.reload();
       }, 300000); 
       //emp_attendance
     }
     }
     else if(report=="manpowerreport"){ 
      window.location.href="https://erp.smpinfra.in/smp/admin/manpowerreport.php?siteid="+siteid+"&datecreated="+datecreated+"&datecreated1="+datecreated1; 
         setTimeout(() => {
           window.location.reload();
         }, 300000);
       //emp_attendance 
     }
     else{
       alert('select document');
     }
   }
 } 
 const [startDate, setStartDate] = useState('');
 const [endDate, setEndDate] = useState(''); 
 const handleStartDateChange = (e) => {
   const selectedDate = new Date(e.target.value);setStartDate(selectedDate.toISOString().slice(0, 10)); 
   const endDate = new Date(selectedDate);
   endDate.setMonth(endDate.getMonth() + 1);
   endDate.setDate(0); 
   setEndDate(endDate.toISOString().slice(0, 10));
 }
 const logout=()=>{
  setIsLoggedIn(false);
  sessionStorage.removeItem("logstatus");
  window.location.reload();
}
 const handleActivity = () => {
  // Reset the logout timer on user activity
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
  const timer = setTimeout(logout, 180 * 60 * 1000); // Reset timer to 3 minutes
  setLogoutTimer(timer);
}
let date = [];
 return ( <div><Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'80rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}> <br/>
 <div style={{ overflowX: 'auto', maxHeight: '200px'}}>  
 <Table striped bordered hover responsive>
            <tbody>  <tr><td>Project</td>
            {datadate.map((item2, index2) => { 
  date.push(item2); 
  return (
    <td key={index2}>{item2}</td>
  );
})}
</tr> 
<tr>
  <td>{snameses}</td>
  {date.map((dateItem1, index4) => {
    const parts = dateItem1.split('-');
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    console.log('Formatted Date:', formattedDate);
    const isDateMatch = datamain.some(data => data.date_reported == formattedDate);
    const isSiteMatch = datamain.some(data => data.sitename == snameses);
    console.log('isDateMatch:', isDateMatch, 'isSiteMatch:', isSiteMatch);
    return (
      <td key={index4}>
        {isDateMatch && isSiteMatch ? '✔' : '✘'}
      </td>
    );
  })}
</tr> 
              </tbody></Table></div>

 </Container><br/>
 <Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'80rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)'}} fluid  onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
 <br/>
 <h2 align="center">Worker Attendance</h2>
<hr/> 
<Row align="left" style={{padding:'10px'}}>
 <Col md={1}>Select Document</Col>
               <Col md={10}>
               <Form.Select id="report" name="report" onChange={hide}> 
               <option hidden>Select Document</option>
               <option value="contractorattendance">Contractor Attendance</option>
               <option value="staffattendance">Employee Attendance</option>
               <option value="manpowerreport">Man Power Report</option> 
     </Form.Select>
               </Col></Row>
 <Row align="left" style={{padding:'10px'}}> 
 <Col md={1}>Project Name</Col>
               <Col md={10}>
               <Form.Control id="siteid" name="siteid" type="text" readOnly hidden/>  
               <Form.Control type="text" id="snameses" readOnly />
               </Col></Row> <Row align="left" style={{padding:'10px'}}>
               <Col md={1}>From Date</Col>
               <Col md={5}><Form.Control type="date" id="datecreated" name="datecreated" value={startDate} onChange={handleStartDateChange}/>
               </Col> 
               <Col md={1}>To Date</Col>
<Col md={5}><Form.Control type="date" id="datecreated1" name="datecreated1" min={startDate} max={endDate} />
               </Col>
               </Row> 
               <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} onClick={redirect}>Print Report</Button>
               <br/> 
   </Container></div>
 );
}

export default Worker_attendance_sheet_user;