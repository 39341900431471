import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';  
 
function Site_agency_catagory_service_mapping() { 
  const [data, setData] = useState([]); 
  const [dataemp, setDataemp] = useState([]);  
  const [defaultOption, setDefaultOption] = useState(''); 
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  useEffect(()=>{
		$.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/site_sel.php",success(data){
		  var jsonData = JSON.parse(data);
		  var table = "<option value=''>Select Site</option>";
		  for(let i=0;i<jsonData.length;i++){
			  if(jsonData[i].status !== 0){
			  table+="<option value='"+jsonData[i].site_id+"'>"+jsonData[i].site_name+"</option>";
			  }
		  } 
		  document.getElementById("siteid").innerHTML = table; 
		  try{
		  var qstring = window.location.search;
		  var params = new URLSearchParams(qstring);
		  var id = params.get('did');
		  }
		  catch(e){
			  id = '';
		  }
		  $('#siteid').val(id);    
      $('#siteid').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here
      }); 
	  }});
  },[]); 
  function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const time = `${hours}:${minutes}`;
    $('#time').val(time);
  } 
  getCurrentTime();
  const fetchagency = async () => {
    var siteid = $('#siteid').val();   
    try {
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/agency_sel_2.php",data:{siteid:siteid},success(data){ 
        var jsonData1 = JSON.parse(data);  
        setData(jsonData1);
    }});
    $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/emp_sel_priority.php",data:{siteid:siteid},success(data){
        var jsonData3 = JSON.parse(data);  
        setDataemp(jsonData3);  
      }}); 
  }
  catch (error) {
    console.error('Error fetching data:', error);
  }  
}  
 
/*const send_data_overwrite = (event) =>
{   var date = $('#date').val();
var time = $('#time').val();
var smain = $('#siteid').val(); 
 for (let i = 0; i < data.length; i++) { 
   var remark = $('#reamrksite').val(); 
   var agency = $('#agency'+[i]).val();
   var siteid = $('#siteid').val(); 
   var cat = $('#cat'+[i]).val();   
   var aid = $('#agencyid'+[i]).val();
   var cper = $('#carpenterperson'+[i]).val();
   var bper = $('#barbenderperson'+[i]).val();
   var hper = $('#helperperson'+[i]).val();
   var mper = $('#masonperson'+[i]).val();
   var mlab = $('#malelabperson'+[i]).val();
   var flab = $('#femalelabperson'+[i]).val();
   var supper = $('#supervisorperson'+[i]).val();
   var skillper = $('#skilledperson'+[i]).val();
   var unskillper = $('#unskilledperson'+[i]).val(); 
          $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/map_add_overwrite.php",data:{ agency:agency,cat:cat,siteid:siteid,aid:aid,cper:cper,bper:bper,hper:hper,mper:mper,mlab:mlab,flab:flab,supper:supper,skillper:skillper,unskillper:unskillper,date:date,time:time,remark:remark},success(datanew){
       if(datanew){
        swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
          window.location.reload();
        });
         } 
       }}); 
 } 
 for (let i = 1; i <= 4; i++) { 
  var remarkextra = $('#reamrksite').val(); 
  var agencyextra = $('#agencyextra'+[i]).val();
if(agencyextra!==null || agencyextra!==""){
  var siteextraid = $('#siteid').val(); 
  var catextra = $('#catextra'+[i]).val();   
  var cperextra = $('#carpenterpersonextra'+[i]).val();
  var bperextra = $('#barbenderpersonextra'+[i]).val();
  var hperextra = $('#helperpersonextra'+[i]).val();
  var mperextra = $('#masonpersonextra'+[i]).val();
  var mlabextra = $('#malelabpersonextra'+[i]).val();
  var flabextra = $('#femalelabpersonextra'+[i]).val();
  var supperextra = $('#supervisorpersonextra'+[i]).val();
  var skillperextra = $('#skilledpersonextra'+[i]).val();
  var unskillperextra = $('#unskilledpersonextra'+[i]).val(); 
         $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/map_add_extra_overwrite.php",data:{agencyextra:agencyextra,remarkextra:remarkextra,siteextraid:siteextraid,catextra:catextra,cperextra:cperextra,bperextra:bperextra,hperextra:hperextra,mperextra:mperextra,mlabextra:mlabextra,flabextra:flabextra,supperextra:supperextra,skillperextra:skillperextra,unskillperextra:unskillperextra,date:date,time:time},success(datanew){
      if(datanew){
        swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
          window.location.reload();
        });
        }  
      }}); 
} 
 }
 for (let i = 0; i < dataemp.length; i++) {
  var emp = $('#emp'+[i]).val(); 
  var sitename = $('#sitename'+[i]).val();
  //alert(sitename);
  var code = $('#code'+[i]).val();
  var des = $('#des'+[i]).val();
  var no = $('#no'+[i]).val();
 // alert(no);
  var remark = $('#remark'+[i]).val(); 
  $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/emp_map_add_overwrite.php",data:{emp:emp,code:code,des:des,no:no,remark:remark,sitename:sitename,date:date,time:time},success(datanew){
      if(datanew == 1){
        swal({title:"Successful!",text:"Added Successfully",icon:"success", buttons: ["No, cancel!", "Download"]}).then((result)=>{
         if(result){
          window.location.href="https://erp.smpinfra.in/smp/admin/attendancedownload.php?sitename="+sitename+"&date="+date;  
   setTimeout(() => {
     window.location.reload();
   }, 2000); 
         }else{
           window.location.reload();
         }
        });
        }  
      }}); 
}
} */
const send_data = async (event) => {
  var date = $('#date').val();
  var time = $('#time').val();
  var smain = $('#siteid').val();

  if (smain == null || smain == "") {
    swal({ title: "Error!", text: "Project Can Not Be Blank!", icon: "error", buttons: false, timer: 2000 }).then(() => {
      $('#siteid').focus();
    });
  } else if (date == null || date == "") {
    swal({ title: "Error!", text: "Date Can Not Be Blank!", icon: "error", buttons: false, timer: 2000 }).then(() => {
      $('#date').focus();
    });
  } else if (time == null || time == "") {
    swal({ title: "Error!", text: "Time Can Not Be Blank!", icon: "error", buttons: false, timer: 2000 }).then(() => {
      $('#time').focus();
    });
  } else {
    const handleFirstLoop = (i) => {
      return new Promise((resolve, reject) => {
        var remark = $('#reamrksite').val();
        var agency = $('#agency' + [i]).val();
        var siteid = $('#siteid').val();
        var cat = $('#cat' + [i]).val();
        var aid = $('#agencyid' + [i]).val();
        var cper = $('#carpenterperson' + [i]).val();
        var bper = $('#barbenderperson' + [i]).val();
        var hper = $('#helperperson' + [i]).val();
        var mper = $('#masonperson' + [i]).val();
        var mlab = $('#malelabperson' + [i]).val();
        var flab = $('#femalelabperson' + [i]).val();
        var supper = $('#supervisorperson' + [i]).val();
        var skillper = $('#skilledperson' + [i]).val();
        var unskillper = $('#unskilledperson' + [i]).val();

        $.ajax({
          type: "POST",
          url: "https://erp.smpinfra.in/smp/admin/map_add.php",
          data: {
            agency: agency,
            cat: cat,
            siteid: siteid,
            aid: aid,
            cper: cper,
            bper: bper,
            hper: hper,
            mper: mper,
            mlab: mlab,
            flab: flab,
            supper: supper,
            skillper: skillper,
            unskillper: unskillper,
            date: date,
            time: time,
            remark: remark
          },
          success(datanew) {
            resolve(datanew);
          },
          error(err) {
            reject(err);
          }
        });
      });
    };

    const handleSecondLoop = (i) => {
      return new Promise((resolve, reject) => {
        var remarkextra = $('#reamrksite').val();
        var agencyextra = $('#agencyextra' + [i]).val();
        if (agencyextra !== null || agencyextra !== "") {
          var siteextraid = $('#siteid').val();
          var catextra = $('#catextra' + [i]).val();
          var cperextra = $('#carpenterpersonextra' + [i]).val();
          var bperextra = $('#barbenderpersonextra' + [i]).val();
          var hperextra = $('#helperpersonextra' + [i]).val();
          var mperextra = $('#masonpersonextra' + [i]).val();
          var mlabextra = $('#malelabpersonextra' + [i]).val();
          var flabextra = $('#femalelabpersonextra' + [i]).val();
          var supperextra = $('#supervisorpersonextra' + [i]).val();
          var skillperextra = $('#skilledpersonextra' + [i]).val();
          var unskillperextra = $('#unskilledpersonextra' + [i]).val();

          $.ajax({
            type: "POST",
            url: "https://erp.smpinfra.in/smp/admin/map_add_extra.php",
            data: {
              agencyextra: agencyextra,
              remarkextra: remarkextra,
              siteextraid: siteextraid,
              catextra: catextra,
              cperextra: cperextra,
              bperextra: bperextra,
              hperextra: hperextra,
              mperextra: mperextra,
              mlabextra: mlabextra,
              flabextra: flabextra,
              supperextra: supperextra,
              skillperextra: skillperextra,
              unskillperextra: unskillperextra,
              date: date,
              time: time
            },
            success(datanew) {
              resolve(datanew);
            },
            error(err) {
              reject(err);
            }
          });
        } else {
          resolve();
        }
      });
    };

    const handleThirdLoop = (i) => {
      return new Promise((resolve, reject) => {
        var emp = $('#emp' + [i]).val();
        var sitename = $('#sitename' + [i]).val();
        var code = $('#code' + [i]).val();
        var des = $('#des' + [i]).val();
        var no = $('#no' + [i]).val();
        var remark = $('#remark' + [i]).val();

        $.ajax({
          type: "POST",
          url: "https://erp.smpinfra.in/smp/admin/emp_map_add.php",
          data: { emp: emp, code: code, des: des, no: no, remark: remark, sitename: sitename, date: date, time: time },
          success(datanew) {
            if (datanew == 1) {
              resolve(1);
            } else {
              resolve(0);
            }
          },
          error(err) {
            reject(err);
          }
        });
      });
    };

    try {
      // Handle the first loop
      await Promise.all(data.map((_, i) => handleFirstLoop(i)));

      // Handle the second loop
      await Promise.all(Array.from({ length: 4 }, (_, i) => handleSecondLoop(i + 1)));

      // Handle the third loop
      const results = await Promise.all(dataemp.map((_, i) => handleThirdLoop(i)));

      // Check if any of the third loop calls were successful
      if (results.some(result => result === 1)) {
        swal({ title: "Successful!", text: "Added Successfully", icon: "success", buttons: ["No, cancel!", "Download"] }).then((result) => {
          if (result) {
            const sitename = $('#sitename0').val();
            window.location.href = "https://erp.smpinfra.in/smp/admin/attendancedownload.php?sitename=" + sitename + "&date=" + date;
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            window.location.reload();
          }
        });
      } else {
        swal({ title: "Successful!", text: "Added Successfully", icon: "success", buttons: false, timer: 2000 }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }
};


/*const send_data = (event) =>
{   var date = $('#date').val();
var time = $('#time').val();
var smain = $('#siteid').val();
if(smain==null || smain==""){
  swal({title:"Error!",text:"Project Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
    $('#siteid').focus();
  });
}
else if(date==null || date==""){
  swal({title:"Error!",text:"Date Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
    $('#date').focus();
  });
}
else if(time==null || time==""){
  swal({title:"Error!",text:"Time Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
    $('#time').focus();
  });
}else{
 for (let i = 0; i < data.length; i++) { 
   var remark = $('#reamrksite').val(); 
   var agency = $('#agency'+[i]).val();
   var siteid = $('#siteid').val(); 
   var cat = $('#cat'+[i]).val();  
   var aid = $('#agencyid'+[i]).val();
   var cper = $('#carpenterperson'+[i]).val();
   var bper = $('#barbenderperson'+[i]).val();
   var hper = $('#helperperson'+[i]).val();
   var mper = $('#masonperson'+[i]).val();
   var mlab = $('#malelabperson'+[i]).val();
   var flab = $('#femalelabperson'+[i]).val();
   var supper = $('#supervisorperson'+[i]).val();
   var skillper = $('#skilledperson'+[i]).val();
   var unskillper = $('#unskilledperson'+[i]).val(); 
          $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/map_add.php",data:{ agency:agency,cat:cat,siteid:siteid,aid:aid,cper:cper,bper:bper,hper:hper,mper:mper,mlab:mlab,flab:flab,supper:supper,skillper:skillper,unskillper:unskillper,date:date,time:time,remark:remark},success(datanew){
       if(datanew){
        swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
          window.location.reload();
        });
         }  
       }}); 
 } 
 for (let i = 1; i <= 4; i++) { 
  var remarkextra = $('#reamrksite').val(); 
  var agencyextra = $('#agencyextra'+[i]).val();
if(agencyextra!==null || agencyextra!==""){
  var siteextraid = $('#siteid').val(); 
  var catextra = $('#catextra'+[i]).val();   
  var cperextra = $('#carpenterpersonextra'+[i]).val();
  var bperextra = $('#barbenderpersonextra'+[i]).val();
  var hperextra = $('#helperpersonextra'+[i]).val();
  var mperextra = $('#masonpersonextra'+[i]).val();
  var mlabextra = $('#malelabpersonextra'+[i]).val();
  var flabextra = $('#femalelabpersonextra'+[i]).val();
  var supperextra = $('#supervisorpersonextra'+[i]).val();
  var skillperextra = $('#skilledpersonextra'+[i]).val();
  var unskillperextra = $('#unskilledpersonextra'+[i]).val(); 
         $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/map_add_extra.php",data:{agencyextra:agencyextra,remarkextra:remarkextra,siteextraid:siteextraid,catextra:catextra,cperextra:cperextra,bperextra:bperextra,hperextra:hperextra,mperextra:mperextra,mlabextra:mlabextra,flabextra:flabextra,supperextra:supperextra,skillperextra:skillperextra,unskillperextra:unskillperextra,date:date,time:time},success(datanew){
      if(datanew){
        swal({title:"Successful!",text:"Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
          window.location.reload();
        });
        }  
      }}); 
} 
 }
 for (let i = 0; i < dataemp.length; i++) {
   var emp = $('#emp'+[i]).val(); 
   var sitename = $('#sitename'+[i]).val();
   var code = $('#code'+[i]).val();
   var des = $('#des'+[i]).val();
   var no = $('#no'+[i]).val();
  // alert(no);
   var remark = $('#remark'+[i]).val(); 
   $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/emp_map_add.php",data:{emp:emp,code:code,des:des,no:no,remark:remark,sitename:sitename,date:date,time:time},success(datanew){
       if(datanew == 1){
         swal({title:"Successful!",text:"Added Successfully",icon:"success", buttons: ["No, cancel!", "Download"]}).then((result)=>{
          if(result){
           window.location.href="https://erp.smpinfra.in/smp/admin/attendancedownload.php?sitename="+sitename+"&date="+date;  
    setTimeout(() => {
      window.location.reload();
    }, 2000); 
          }else{
            window.location.reload();
          }
         });
         } 
         else{
          swal({title:"Error!",text:"Already Added",icon:"error", buttons: ["No, cancel!", "Overwrite Data"]}).then((result)=>{
            if(result){ 
              send_data_overwrite();
            } 
           });
         } 
       }}); 
 }}
} */ 
const total = (event) =>
{   
 var sumcarpenter = 0;
 var sumbar = 0;
 var sumhelper = 0;
 var summason = 0;
 var summale = 0;
 var sumfemale = 0;
 var sumsuper = 0;
 var sumskill = 0;
 var sumunskill = 0;
 for (let i = 0; i < data.length; i++) {   
   var cper1 = parseInt($('#carpenterperson'+[i]).val())||0;
   sumcarpenter += cper1;
   var bper1 = parseInt($('#barbenderperson'+[i]).val())||0;
   sumbar += bper1;
   var hper1 = parseInt($('#helperperson'+[i]).val())||0;
   sumhelper += hper1;
   var mper1 = parseInt($('#masonperson'+[i]).val())||0;
   summason += mper1;
   var mlab1 = parseInt($('#malelabperson'+[i]).val())||0;
   summale += mlab1;
   var flab1 = parseInt($('#femalelabperson'+[i]).val())||0;
   sumfemale += flab1;
   var supper1 = parseInt($('#supervisorperson'+[i]).val())||0;
   sumsuper += supper1;
   var skillper1 = parseInt($('#skilledperson'+[i]).val())||0;
   sumskill += skillper1;
   var unskillper1 = parseInt($('#unskilledperson'+[i]).val())||0;
   sumunskill += unskillper1;
   var sum = cper1 + bper1 + hper1 + mper1 + mlab1 + flab1 + supper1 + skillper1 +unskillper1; 
   $("#total"+[i]).val(sum); 
 } 
 for(let j = 1; j < 5; j++){
  var cper2 = parseInt($('#carpenterpersonextra'+[j]).val())||0;
  sumcarpenter += cper2;
  var bper2 = parseInt($('#barbenderpersonextra'+[j]).val())||0;
   sumbar += bper2;
   var hper2 = parseInt($('#helperpersonextra'+[j]).val())||0;
   sumhelper += hper2;
   var mper2 = parseInt($('#masonpersonextra'+[j]).val())||0;
   summason += mper2;
   var mlab2 = parseInt($('#malelabpersonextra'+[j]).val())||0;
   summale += mlab2;
   var flab2 = parseInt($('#femalelabpersonextra'+[j]).val())||0;
   sumfemale += flab2;
   var supper2 = parseInt($('#supervisorpersonextra'+[j]).val())||0;
   sumsuper += supper2;
   var skillper2 = parseInt($('#skilledpersonextra'+[j]).val())||0;
   sumskill += skillper2;
   var unskillper2 = parseInt($('#unskilledpersonextra'+[j]).val())||0;
   sumunskill += unskillper2;
   var sum1 = cper2 + bper2 + hper2 + mper2 + mlab2 + flab2 + supper2 + skillper2 +unskillper2; 
   $("#totalextra"+[j]).val(sum1);
 } 
 var totalall = sumcarpenter + sumbar + sumhelper + summason + summale + sumfemale + sumsuper + sumskill + sumunskill;
 $("#totalcarpenterperson").val(sumcarpenter);
 $("#totalbarbenderperson").val(sumbar);
 $("#totalhelperperson").val(sumhelper);
 $("#totalmasonperson").val(summason);
 $("#totalmalelabperson").val(summale);
 $("#totalfemalelabperson").val(sumfemale);
 $("#totalsupervisorperson").val(sumsuper);
 $("#totalskilledperson").val(sumskill);
 $("#totalunskilledperson").val(sumunskill);
 $("#totalall").val(totalall);
} 
  
const handleKeyPress = (event, index, field) => {
  const { keyCode } = event;
  if (keyCode === 38 || keyCode === 40) {
      const nextIndex = keyCode === 38 ? index - 1 : index + 1;
      const nextField = document.getElementById(`${field}${nextIndex}`);
      if (nextField) {
          nextField.focus();
          event.preventDefault();
      }
  }
   else if (keyCode === 39) { // Left or Right arrow key 
    if(field==='carpenterperson'){
      const nextField1 = document.getElementById(`barbenderperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    } }
    if(field==='barbenderperson'){
      const nextField1 = document.getElementById(`helperperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
  }
    if(field==='helperperson'){
      const nextField1 = document.getElementById(`masonperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    }
     if(field==='masonperson'){
      const nextField1 = document.getElementById(`malelabperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='malelabperson'){
      const nextField1 = document.getElementById(`femalelabperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='femalelabperson'){
      const nextField1 = document.getElementById(`supervisorperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='supervisorperson'){
      const nextField1 = document.getElementById(`skilledperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='skilledperson'){
      const nextField1 = document.getElementById(`unskilledperson${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='no'){
      const nextField1 = document.getElementById(`remark${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    }  

    if(field==='agencyextra'){
      const nextField1 = document.getElementById(`catextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    } }
    if(field==='catextra'){
      const nextField1 = document.getElementById(`carpenterpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    } }
    if(field==='carpenterpersonextra'){
      const nextField1 = document.getElementById(`barbenderpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    } }
    if(field==='barbenderpersonextra'){
      const nextField1 = document.getElementById(`helperpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
  }
    if(field==='helperpersonextra'){
      const nextField1 = document.getElementById(`masonpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    }
     if(field==='masonpersonextra'){
      const nextField1 = document.getElementById(`malelabpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='malelabpersonextra'){
      const nextField1 = document.getElementById(`femalelabpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='femalelabpersonextra'){
      const nextField1 = document.getElementById(`supervisorpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='supervisorpersonextra'){
      const nextField1 = document.getElementById(`skilledpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
    if(field==='skilledpersonextra'){
      const nextField1 = document.getElementById(`unskilledpersonextra${index}`);
      if (nextField1) {
        nextField1.focus();
        event.preventDefault();
    }
    } 
}
 else if (keyCode === 37){
  if(field==='unskilledperson'){
    const nextField1 = document.getElementById(`skilledperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='skilledperson'){
    const nextField1 = document.getElementById(`supervisorperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='supervisorperson'){
    const nextField1 = document.getElementById(`femalelabperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='femalelabperson'){
    const nextField1 = document.getElementById(`malelabperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='malelabperson'){
    const nextField1 = document.getElementById(`masonperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='masonperson'){
    const nextField1 = document.getElementById(`helperperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }
  if(field==='helperperson'){
    const nextField1 = document.getElementById(`barbenderperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }
  if(field==='barbenderperson'){
    const nextField1 = document.getElementById(`carpenterperson${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }

  if(field==='unskilledpersonextra'){
    const nextField1 = document.getElementById(`skilledpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='skilledpersonextra'){
    const nextField1 = document.getElementById(`supervisorpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='supervisorpersonextra'){
    const nextField1 = document.getElementById(`femalelabpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='femalelabpersonextra'){
    const nextField1 = document.getElementById(`malelabpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='malelabpersonextra'){
    const nextField1 = document.getElementById(`masonpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
  if(field==='masonpersonextra'){
    const nextField1 = document.getElementById(`helperpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }
  if(field==='helperpersonextra'){
    const nextField1 = document.getElementById(`barbenderpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }
  if(field==='barbenderpersonextra'){
    const nextField1 = document.getElementById(`carpenterpersonextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }
  if(field==='carpenterpersonextra'){
    const nextField1 = document.getElementById(`catextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }
  if(field==='catextra'){
    const nextField1 = document.getElementById(`agencyextra${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  }

  if(field==='remark'){
    const nextField1 = document.getElementById(`no${index}`);
    if (nextField1) {
      nextField1.focus();
      event.preventDefault();
  }
  } 
 }
 else{}
} 
const logout=()=>{
  setIsLoggedIn(false);
  sessionStorage.removeItem("logstatus");
  window.location.reload();
}
 const handleActivity = () => {
  // Reset the logout timer on user activity
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
  const timer = setTimeout(logout, 180 * 60 * 1000); // Reset timer to 3 minutes
  setLogoutTimer(timer);
};
const handledate = (event) =>{
  const selectedDateValue  = $('#date').val(); 
  const selectedDate = new Date(selectedDateValue);
    const selectedDay = selectedDate.getDay(); 
    const selectedDay1 = selectedDate.getDate();
    const selectedMonth = selectedDate.getMonth(); 
    let defaultOption = 'p'; // Default to 'present'
    if (selectedDay === 0) {
        defaultOption = 'wo'; // Set to 'Week Off' if it's Sunday
         console.log('sunday');
         for (let i = 0; i < dataemp.length; i++) { 
        defaultOption = 'wo'; // Set to 'Week Off' if it's Sunday
           $('#no'+[i]).val(defaultOption);
     }
    } 
    // Months in JavaScript are zero-indexed, so January is 0
    else if (selectedDay1 === 26 && selectedMonth === 0) {
      defaultOption = 'rh'; // Set to 'Week Off' if it's Sunday
      console.log('rh');
      for (let i = 0; i < dataemp.length; i++) { 
     defaultOption = 'rh'; // Set to 'Week Off' if it's Sunday
        $('#no'+[i]).val(defaultOption);
    }}
    else if (selectedDay1 === 15 && selectedMonth === 7) {
      defaultOption = 'rh'; // Set to 'Week Off' if it's Sunday
      console.log('rh');
      for (let i = 0; i < dataemp.length; i++) { 
     defaultOption = 'rh'; // Set to 'Week Off' if it's Sunday
        $('#no'+[i]).val(defaultOption);
    }
}
else if (selectedDay1 === 2 && selectedMonth === 9) {
  defaultOption = 'rh'; // Set to 'Week Off' if it's Sunday
  console.log('rh');
  for (let i = 0; i < dataemp.length; i++) { 
 defaultOption = 'rh'; // Set to 'Week Off' if it's Sunday
    $('#no'+[i]).val(defaultOption);
}
}else{}
} 
return ( 
<div><Container style={{ color: "#565656", padding: '0', backgroundColor: 'white', maxWidth: '80rem', borderRadius: '15px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)' }} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
   <br />
   <h2 align="center">Worker Attendance</h2>
   <hr />
   <Row align="left" style={{ padding: '10px' }}>
   <Col md={6}>
       <Form.Select id="siteid" onChange={fetchagency}>
       </Form.Select> </Col>
       <Col md={6}>
     <Form.Control type="date" id="date" name="date" onChange={(event) => { 
  handledate(event);}}/> 
     </Col> 
     <Form.Control type="text" id="time" name="time" hidden/>  
   </Row>  
   <div className="tableFixHead" style={{ overflow: 'auto', height: '400px' }}>
     <Table striped bordered hover responsive> 
     <thead style={{position:'sticky',zIndex:'1',top:'0' }}> 
     <tr> 
       <th></th>
       <th></th>
       <th></th>
       <th></th>
       <th></th>
       <th></th>
       <th colSpan="2"><center>LABOUR</center></th>
       <th></th>
       <th colSpan="2"><center>OTHER</center></th> 
       <th></th>
     </tr>
       <tr>
         <th>Code </th>
         <th>Contractor  </th>
         <th>Catagory </th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Carpenter</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Bar-bender</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Helper</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Mason</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Male</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Female</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Supervisor</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Skilled</th> 
         <th style={{ textTransform: 'uppercase', writingMode: "vertical-rl" }}>Unskilled</th> 
         <th>Total</th> 
       </tr>
     </thead>  <tbody style={{ height: "10px !important", overflow: "scroll"}}> 
     <div style={{ maxHeight: '400px', overflowY: 'auto' }}></div>
      {data.map((item, index) =>  ( <tr key={index}> 
        <td><Form.Control type="text" id={`agencycode${index}`} name={`agencycode${index}`} value={`${item.agency_code}`} readOnly /> </td>
      <td><Form.Control type="text" id={`agency${index}`} name={`agency${index}`} value={`${item.contractor_name}`} readOnly /> 
           <Form.Control type="text" id={`site${index}`} name={`site${index}`} value={`${item.site}`} readOnly hidden/>
           <Form.Control type="text" id={`siteextra`} name={`siteextra`} value={`${item.site}`} readOnly hidden/>
           <Form.Control type="text" id={`agencyid${index}`} name={`agencyid${index}`} value={`${item.agency_id}`} readOnly hidden/>
            </td>
           <td><Form.Control type="text" id={`cat${index}`} name={`cat${index}`} value={`${item.category}`} readOnly/> </td> 
           <td> <Form.Control type="text" id={`carpenterperson${index}`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e, index,'carpenterperson')} /></td>
           <td> <Form.Control type="text" id={`barbenderperson${index}`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e, index,'barbenderperson')}/></td>
           <td> <Form.Control type="text" id={`helperperson${index}`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e, index,'helperperson')}/></td>
           <td> <Form.Control type="text" id={`masonperson${index}`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e, index,'masonperson')}/></td>
           <td> <Form.Control type="text" id={`malelabperson${index}`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e, index,'malelabperson')}/></td>
           <td> <Form.Control type="text" id={`femalelabperson${index}`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e, index,'femalelabperson')}/></td>
           <td> <Form.Control type="text" id={`supervisorperson${index}`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e, index,'supervisorperson')}/></td>
           <td> <Form.Control type="text" id={`skilledperson${index}`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e, index,'skilledperson')}/></td>
           <td> <Form.Control type="text" id={`unskilledperson${index}`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e, index,'unskilledperson')}/></td>
           <td> <Form.Control type="text" id={`total${index}`} style={{ width: '50px' }} readOnly/></td>
             </tr> ))} 
             <tr>
             <td>  </td>
             <td><Form.Control type="text" id={`agencyextra1`} name={`agencyextra1`}  onKeyDown={(e) => handleKeyPress(e,1,'agencyextra')} />  
            </td>
           <td><Form.Control type="text" id={`catextra1`} name={`catextra1`} onKeyDown={(e) => handleKeyPress(e,1,'catextra')}/> </td> 
           <td> <Form.Control type="text" id={`carpenterpersonextra1`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,1,'carpenterpersonextra')} /></td>
           <td> <Form.Control type="text" id={`barbenderpersonextra1`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,1,'barbenderpersonextra')}/></td>
           <td> <Form.Control type="text" id={`helperpersonextra1`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,1,'helperpersonextra')}/></td>
           <td> <Form.Control type="text" id={`masonpersonextra1`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,1,'masonpersonextra')} /></td>
           <td> <Form.Control type="text" id={`malelabpersonextra1`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,1,'malelabpersonextra')}/></td>
           <td> <Form.Control type="text" id={`femalelabpersonextra1`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,1,'femalelabpersonextra')} /></td>
           <td> <Form.Control type="text" id={`supervisorpersonextra1`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,1,'supervisorpersonextra')}/></td>
           <td> <Form.Control type="text" id={`skilledpersonextra1`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,1,'skilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`unskilledpersonextra1`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,1,'unskilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`totalextra1`} style={{ width: '50px' }} readOnly/></td>
             </tr>
             <tr>
             <td> </td>
             <td><Form.Control type="text" id={`agencyextra2`} name={`agencyextra2`}  onKeyDown={(e) => handleKeyPress(e,2,'agencyextra')} />  
            </td>
           <td><Form.Control type="text" id={`catextra2`} name={`catextra2`} onKeyDown={(e) => handleKeyPress(e,2,'catextra')}/> </td> 
           <td> <Form.Control type="text" id={`carpenterpersonextra2`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,2,'carpenterpersonextra')}/></td>
           <td> <Form.Control type="text" id={`barbenderpersonextra2`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,2,'barbenderpersonextra')}/></td>
           <td> <Form.Control type="text" id={`helperpersonextra2`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,2,'helperpersonextra')}/></td>
           <td> <Form.Control type="text" id={`masonpersonextra2`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,2,'masonpersonextra')}/></td>
           <td> <Form.Control type="text" id={`malelabpersonextra2`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,2,'malelabpersonextra')}/></td>
           <td> <Form.Control type="text" id={`femalelabpersonextra2`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,2,'femalelabpersonextra')}/></td>
           <td> <Form.Control type="text" id={`supervisorpersonextra2`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,2,'supervisorpersonextra')}/></td>
           <td> <Form.Control type="text" id={`skilledpersonextra2`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,2,'skilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`unskilledpersonextra2`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,2,'unskilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`totalextra2`} style={{ width: '50px' }} readOnly/></td>
             </tr>
             <tr>
             <td>   </td>
             <td><Form.Control type="text" id={`agencyextra3`} name={`agencyextra3`}  onKeyDown={(e) => handleKeyPress(e,3,'agencyextra')} />  
            </td>
           <td><Form.Control type="text" id={`catextra3`} name={`catextra3`} onKeyDown={(e) => handleKeyPress(e,3,'catextra')}/> </td> 
           <td> <Form.Control type="text" id={`carpenterpersonextra3`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,3,'carpenterpersonextra')}/></td>
           <td> <Form.Control type="text" id={`barbenderpersonextra3`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,3,'barbenderpersonextra')}/></td>
           <td> <Form.Control type="text" id={`helperpersonextra3`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,3,'helperpersonextra')} /></td>
           <td> <Form.Control type="text" id={`masonpersonextra3`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,3,'masonpersonextra')}/></td>
           <td> <Form.Control type="text" id={`malelabpersonextra3`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,3,'malelabpersonextra')}/></td>
           <td> <Form.Control type="text" id={`femalelabpersonextra3`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,3,'femalelabpersonextra')}/></td>
           <td> <Form.Control type="text" id={`supervisorpersonextra3`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,3,'supervisorpersonextra')}/></td>
           <td> <Form.Control type="text" id={`skilledpersonextra3`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,3,'skilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`unskilledpersonextra3`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,3,'unskilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`totalextra3`} style={{ width: '50px' }} readOnly/></td>
             </tr>
             <tr>
             <td>  </td>
             <td><Form.Control type="text" id={`agencyextra4`} name={`agencyextra4`}  onKeyDown={(e) => handleKeyPress(e,4,'agencyextra')} />  
            </td>
           <td><Form.Control type="text" id={`catextra4`} name={`catextra4`} onKeyDown={(e) => handleKeyPress(e,4,'catextra')}/> </td> 
           <td> <Form.Control type="text" id={`carpenterpersonextra4`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,4,'carpenterpersonextra')} /></td>
           <td> <Form.Control type="text" id={`barbenderpersonextra4`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,4,'barbenderpersonextra')}/></td>
           <td> <Form.Control type="text" id={`helperpersonextra4`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,4,'helperpersonextra')}/></td>
           <td> <Form.Control type="text" id={`masonpersonextra4`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,4,'masonpersonextra')}/></td>
           <td> <Form.Control type="text" id={`malelabpersonextra4`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,4,'malelabpersonextra')} /></td>
           <td> <Form.Control type="text" id={`femalelabpersonextra4`} style={{ width: '50px' }} onChange={total}  onKeyDown={(e) => handleKeyPress(e,4,'femalelabpersonextra')}/></td>
           <td> <Form.Control type="text" id={`supervisorpersonextra4`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,4,'supervisorpersonextra')}/></td>
           <td> <Form.Control type="text" id={`skilledpersonextra4`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,4,'skilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`unskilledpersonextra4`} style={{ width: '50px' }} onChange={total} onKeyDown={(e) => handleKeyPress(e,4,'unskilledpersonextra')}/></td>
           <td> <Form.Control type="text" id={`totalextra4`} style={{ width: '50px' }} readOnly/></td>
             </tr> 
             <tr>
              <td></td>
               <td></td>
               <td></td>
               <td> <Form.Control type="text" id="totalcarpenterperson" style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalbarbenderperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalhelperperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalmasonperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalmalelabperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalfemalelabperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalsupervisorperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalskilledperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalunskilledperson`} style={{ width: '50px' }} readOnly/></td>
           <td> <Form.Control type="text" id={`totalall`} style={{ width: '50px' }} readOnly/></td>
             </tr>  </tbody> 
 </Table> </div>
 <Row align="center" style={{ padding: '10px' }}>
     <Col md={12}>
 <Form.Control type="text" id={`reamrksite`} placeholder='Remarks...'/></Col></Row> 
 <br/>
   <h2 align="center">Project Staff</h2>
 <Table striped bordered hover responsive>
     <thead>
       <tr> 
        <th>Code</th>
         <th>Employee Name</th>
         <th>Designation</th> 
         <th>Attendance</th> 
         <th style={{width: "350px"}}>Remark </th>
       </tr>
     </thead> 
     {dataemp.map((item3, index3) => (  <tbody><tr key={index3}> 
      <td><Form.Control type="text" id={`empcode${index3}`} name={`empcode${index3}`} value={`${item3.emp_code}`} readOnly /> </td>
      <td><Form.Control type="text" id={`emp${index3}`} name={`emp${index3}`} value={`${item3.emp_name}`} readOnly /> 
           <Form.Control type="text" id={`code${index3}`} name={`code${index3}`} value={`${item3.emp_code}`} readOnly hidden/> 
           <Form.Control type="text" id={`sitename${index3}`} name={`sitename${index3}`} value={`${item3.emp_location}`} readOnly hidden/>
            </td>
           <td><Form.Control type="text" id={`des${index3}`} name={`des${index3}`} value={`${item3.emp_designation}`} readOnly/> </td> 
              <td>   
               <Form.Select id={`no${index3}`} onKeyDown={(e) => handleKeyPress(e, index3,'no')}> 
               <option value='p'>Present(P)</option>
               <option value='a'>Absent(A)</option>
               <option value='l'>Leave(L)</option>
               <option value='rh'>Registered Holoiday</option>
               <option value='wo'>Week Off</option>
               <option value='hf'>Half Day</option>
               </Form.Select>
                </td>  
              <td><Form.Control type="text" id={`remark${index3}`} onKeyUp={(e) => handleKeyPress(e, index3,'remark')}/> </td>  
             </tr></tbody> ))}  
     </Table>
     <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '100%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={send_data}>Save</Button>
 </Container>
 </div>
);
}

export default Site_agency_catagory_service_mapping;