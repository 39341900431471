import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js"; 
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashRestoreAlt,faEdit } from '@fortawesome/free-solid-svg-icons';

const Agency = () =>{
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]); 
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const handleInputChange = (event) => {
      setQuery(event.target.value);
      fetchSearchResults(event.target.value);
  };

  const fetchSearchResults = (searchQuery) => {
      fetch(`https://erp.smpinfra.in/smp/admin/filter_agency.php?query=${searchQuery}`)
          .then(response => response.json())
          .then(data => {
              setResults(data);
          })
          .catch(error => {
              console.error('Error fetching search results:', error);
          });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(()=>{  
     // Fetch data from server
    $.ajax({
      type:"POST",
      url:"https://erp.smpinfra.in/smp/admin/site_sel.php",
      success: function(data){
      var jsonData = JSON.parse(data);
      var table = "<option value=''>Select Site</option>";
      for(let i=0;i<jsonData.length;i++){
          if(jsonData[i].status != 0){
          table+="<option value='"+jsonData[i].site_code+"'>"+jsonData[i].site_code+"</option>";
          }
          var options = jsonData.filter(item => item.status !== 0).map(item => ({
            value: item.site_code,
            label: item.site_code
          }));
          setSiteOptions(options);
      } 
      document.getElementById("site").innerHTML = table;  
      document.getElementById("site1").innerHTML = table; 
      document.getElementById("site2").innerHTML = table; 
      document.getElementById("site3").innerHTML = table; 
      try{
      var qstring = window.location.search;
      var params = new URLSearchParams(qstring);
      var id = params.get('did');
      }
      catch(e){
          id = '';
      }
      $('#site').val(id);  
      $('#site1').val(id);  
      $('#site2').val(id);  
      $('#site3').val(id);  
       // Attach change event listener to select element
       $('#site').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here
      });
      $('#site1').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here
      });
      $('#site2').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here
      });
      $('#site3').change(function () {
        var selectedValue = $(this).val();
        console.log(selectedValue); // You can do whatever you want with the selected value here
      });
    }
  }); 
 // Empty dependency array means this effect runs only once after the initial render

    try { 
      $.ajax({type:"POST",
      url:"https://erp.smpinfra.in/smp/admin/select_agency_all.php" ,
      success:function(data){
        var jsonData = JSON.parse(data); 
        document.cookie = jsonData.length; 
        setData(jsonData);
      }}); 
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }  
  }, []);
  const agency_add=(event)=>{ 
    var agencycode = $('#agencycode').val();  
    var agencyname = $('#agencyname').val();  
    var sitename = $('#site').val();
    var sitename1 = $('#site1').val();
    var sitename2 = $('#site2').val();
    var sitename3 = $('#site3').val();
    var catname = $('#catname').val();
    var contactperson = $('#coperson').val();
		var agencycontactno = $('#agencycontactno').val();
    var type = $('#type').val();
    var agencyadd = $('#agencyadd').val();
    var gst = $('#gst').val();
    var pan = $('#pan').val();
    var bankname = $('#bankname').val();
    var accountno = $('#accountno').val();
    var ifscno = $('#ifscno').val();
    //alert(contactperson);
   try{
    if(agencycode==null || agencycode==""){
      swal({title:"Error!",text:"Agency Code Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#agencycode').focus();
      });
    }
    else if(agencyname==null || agencyname==""){
      swal({title:"Error!",text:"Agency Name Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#agencyname').focus();
      });
    }
    else if(agencycontactno==null || agencycontactno==""){
      swal({title:"Error!",text:"Agency Contact No Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#agencycontactno').focus();
      });
    } 
   else{$.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/agency_add.php",data:{agencycode:agencycode,agencyname:agencyname,catname:catname,sitename:sitename,sitename1:sitename1,sitename2:sitename2,sitename3:sitename3,contactperson:contactperson,agencycontactno:agencycontactno,type:type,agencyadd:agencyadd,gst:gst,pan:pan,bankname:bankname,accountno:accountno,ifscno:ifscno},success(data){
      if(data){
        swal({title:"Successful!",text:"New Contractor Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
          window.location.reload();
        });
        }
        else{
          swal({title:"Error!",text:"Contractor Already Exists",icon:"error",buttons:false,timer:2000}).then(()=>{
            window.location.reload();
          });
        }
    }});}}
      catch(e){
        alert(e);
      }
  }
  const export_agency = (event) =>{
   window.location.href = "https://erp.smpinfra.in/smp/admin/sample_export_agency.php";
  }  
  const exportmaster = (event) =>{
    window.location.href = "https://erp.smpinfra.in/smp/admin/export_agency_master.php";
   }  
  const [inputValues, setInputValues] = useState({}); 
  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    setInputValues(prevState => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [name]: value
      }
    }));
  }; 
 
const logout=()=>{
  setIsLoggedIn(false);
  sessionStorage.removeItem("logstatus");
  window.location.reload();
}
 const handleActivity = () => {
  // Reset the logout timer on user activity
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
  const timer = setTimeout(logout, 180 * 60 * 1000); // Reset timer to 3 minutes
  setLogoutTimer(timer);
};
    return ( 
        <Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'78rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)',marginTop:'-45px'}} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>  <br/>
                <h2 align="center">Add Contractor</h2> <hr/>
                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} data-bs-toggle="modal" data-bs-target="#exampleModal">Import File</Button>    
         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModalLabel">Import File</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <form method="POST" action="https://erp.smpinfra.in/smp/admin/agencyimport.php" encType="multipart/form-data">
               <Row align="left">
              <Col md={6}>
                <Form.Control type="file" name="import_file" id="import_file"/>
                </Col>
                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '15%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}name="save_excel_data">Import File</Button> 
                <Button type="button" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '18%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={exportmaster}>Export Contractor Master</Button>
                <Button type="button" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '15%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={export_agency}>Export Sample File</Button>
                </Row></form> <br/>  
               <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Contractor Code</th>
                    <th>Contractor Name </th>
                    <th>Category</th>
                    <th>Project1</th>
                    <th>Project2</th>
                    <th>Project3</th>
                    <th>Project4</th>
                    <th>Contact Person</th>
                    <th>Mobile No</th> 
                    <th>Type</th> 
                    <th>Address</th> 
                    <th>GST</th> 
                    <th>PAN</th> 
                    <th>Bank</th> 
                    <th>Account Number</th> 
                    <th>ISFC Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Din-Yog_Pal</td>
                    <td>Dinesh Yogeshwar Pal</td>
                    <td>Shuttering work</td>
                    <td>Gcl-Vilayat</td>
                    <td>Bodal</td>
                    <td>SRF-DHJ</td>
                    <td>ALM-HAL</td>
                    <td>Hiren Patel</td>
                    <td>9979284079</td>
                    <td>--</td>
                    <td>B -80, Haridwar Society, Bholav, Bharuch .</td>
                    <td>Nill</td>
                    <td>AORPP1815K</td>
                    <td>ICIC Bank</td>
                    <td>17801517728</td>
                    <td>ICIC0000178</td>
                  </tr>
                </tbody>
          </Table>
               </div>
               <div className="modal-footer">
                <p>Note : Date Must Be In DD-MM-YYYY Format&#160;&#160;</p>
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>

                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}data-bs-toggle="modal" data-bs-target="#exampleModal1">Fill Form</Button>
                <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModal1Label">Add New Contractor</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Code:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Contractor Code" type="text" id="agencycode" name="agencycode"/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Contractor Name" type="text" id="agencyname" name="agencyname"/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Catagory Name:</Col><Col md={6}>
              
                <Form.Select id="catname" name="catname">
                <option hidden>Select Category</option>
                  <option value="Labour Supply">Labour Supply</option>
                  <option value="Shuttering & Steel Work">Shuttering & Steel Work</option>
                  <option value="Shuttering Work">Shuttering Work </option>
                  <option value="Reinforcement Work">Reinforcement Work </option>
                  <option value="B.W & Plaster Work">B.W & Plaster Work</option>
                  <option value="Allu. Windows Fitting">Allu. Windows Fitting</option>
                  <option value="Anti Termmite Work">Anti Termmite Work</option>
                  <option value="Bitumen Carpet">Bitumen Carpet</option>
                  <option value="Coupler Threding">Coupler Threding</option>
                  <option value="Excavation & Dismentalling  Work">Excavation & Dismentalling  Work</option>
                  <option value="Excavation Work">Excavation Work</option>
                  <option value="Fabrication Work">Fabrication Work</option>
                  <option value="Flooring Work">Flooring Work</option>
                  <option value="Fuji Drain Laying">Fuji Drain Laying</option>
                  <option value="Furniture Work">Furniture Work</option> 
                  <option value="Grouting Work">Grouting Work</option>
                  <option value="Ips Work">Ips Work</option>
                  <option value="Misc. Work">Misc. Work</option>
                  <option value="Painting Work">Painting Work</option>
                  <option value="Pile Breaking ">Pile Breaking </option>
                  <option value="Pile Testing Work">Pile Testing Work</option>
                  <option value="Piling Works">Piling Works</option>
                  <option value="Plumbing Work">Plumbing Work</option>
                  <option value="Rebar-Core Cutting Work">Rebar-Core Cutting Work</option>
                  <option value="Rolling Shuttering">Rolling Shuttering</option>
                  <option value="Scaffolder">Scaffolder</option>
                  <option value="Shuttering & Steel Work/Pile Breaking">Shuttering & Steel Work/Pile Breaking</option>
                  <option value="Shuttering Work, B.W">Shuttering Work, B.W</option>
                  <option value="Steel Work">Steel Work</option>
                  <option value="Tile Work">Tile Work</option>
                  <option value="Waterproofing Work">Waterproofing Work</option>
                  <option value="Acid Tiles">Acid Tiles</option>
                  <option value="Painting">Painting</option>
                  <option value="Flooring">Flooring</option>
                  <option value="Jcb and Tractor">Jcb and Tractor</option>
                  <option value="JCB and Cook">JCB and Cook</option>
                  <option value="Masnory and Plaster">Masnory and Plaster</option> 
                  <option value="Other">Other</option>
                </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Name1:</Col><Col md={6}>
                <Form.Select id="site" name="site">  
			          </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Name2:</Col><Col md={6}>
                <Form.Select id="site1" name="site1">  
			          </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Name3:</Col><Col md={6}>
                <Form.Select id="site2" name="site2">  
			          </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Name4:</Col><Col md={6}>
                <Form.Select id="site3" name="site3">  
			          </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contact Person:</Col><Col md={6}>
                <Form.Control placeholder="Enter Contact Person Name" type="text" id="coperson" name="contactperson"/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contact No:</Col><Col md={6}>
                <Form.Control placeholder="Enter ContactNo" type="text" id="agencycontactno" name="agencycontactno"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type" type="text" id="type" name="type"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Address:</Col><Col md={6}>
                <Form.Control placeholder="Enter Address" type="text" id="agencyadd" name="agencyadd"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>GSTIN No:</Col><Col md={6}>
                <Form.Control placeholder="Enter GSTIN" type="text" id="gst" name="gst"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>PAN No:</Col><Col md={6}>
                <Form.Control placeholder="Enter PAN No" type="text" id="pan" name="pan"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Bank Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter Bank Name" type="text" id="bankname" name="bankname"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Account Number:</Col><Col md={6}>
                <Form.Control placeholder="Enter Account Number" type="text" id="accountno" name="accountno"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>IFSC Code</Col><Col md={6}>
                <Form.Control placeholder="Enter IFSC Code" type="text" id="ifscno" name="ifscno"/></Col>
                </Row>
                <br/>
               <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} onClick={agency_add}>Add Contractor</Button></center>
               </div>
               <div className="modal-footer"> 
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>
                <br/><br/> <Row align="left" style={{padding:'10px'}}> <center>
                <Col md={11}>
                <Form.Control type="text"value={query}
                onChange={handleInputChange}
                placeholder="Search by name,code,category,project,contact person,contact no,type,address,gst,pan-no,bank,account-no or ifsc-code" /></Col></center></Row><br/>
                 {results.length > 0 ? (
                <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  <th>Sr No.</th>
                    <th>Contractor Code</th>
                    <th>Contractor Name </th>
                    <th>Category</th>
                    <th>Project1</th>
                    <th>Project2</th>
                    <th>Project3</th>
                    <th>Project4</th>
                    <th>Contact Person</th>
                    <th>Mobile No</th> 
                    <th>Type</th> 
                    <th>Address</th> 
                    <th>GST</th> 
                    <th>PAN</th> 
                    <th>Bank</th> 
                    <th>Account Number</th> 
                    <th>ISFC Code</th>
                  </tr>
                </thead>
                {results.map((item, index) =>  ( <tbody><tr key={index}>  
                  <td>
                  <form action="https://erp.smpinfra.in/smp/admin/deleteagency.php" method="POST">
                  <Form.Control type="text" id="ageid" name="ageid" value={`${item.agency_id}`} hidden/> 
                <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                </form>
                </td>
                <td>
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#${item.agency_code}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`${item.agency_code}`} tabIndex="-1" aria-labelledby={`Label${item.agency_code}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id={`Label${item.agency_code}`}>Update Contractor</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div> 
               <form action="https://erp.smpinfra.in/smp/admin/updateagency.php" method="POST">
                      <div className="modal-body">
                      <Form.Control type="text" id="ageid" name="ageid" value={item.agency_id} required hidden/>
               <Row align="left" style={{padding:'10px'}}>
                    <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Code:</Col><Col md={6}><Form.Control placeholder="Enter Contractor Code" type="text" id={`agencycode${index}`} name="agencycode" value={inputValues[index]?.agencycode || item.agency_code} required onChange={(e) => handleinputchange(e, index)}/>  
                    </Col>
                    </Row>
                    <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter Contractor Name" type="text" id="agencyname" name="agencyname" value={inputValues[index]?.agencyname || item.contractor_name} required onChange={(e) => handleinputchange(e, index)}/> 
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Catagory Name:</Col><Col md={6}> 
                <Form.Select id="catname" name="catname" value={inputValues[index]?.catname || item.category} onChange={(e) => handleinputchange(e, index)}> 
                  <option value="Labour Supply">Labour Supply</option>
                  <option value="Shuttering & Steel Work">Shuttering & Steel Work</option>
                  <option value="Shuttering Work">Shuttering Work </option>
                  <option value="Reinforcement Work">Reinforcement Work </option>
                  <option value="B.W & Plaster Work">B.W & Plaster Work</option>
                  <option value="Allu. Windows Fitting">Allu. Windows Fitting</option>
                  <option value="Anti Termmite Work">Anti Termmite Work</option>
                  <option value="Bitumen Carpet">Bitumen Carpet</option>
                  <option value="Coupler Threding">Coupler Threding</option>
                  <option value="Excavation & Dismentalling  Work">Excavation & Dismentalling  Work</option>
                  <option value="Excavation Work">Excavation Work</option>
                  <option value="Fabrication Work">Fabrication Work</option>
                  <option value="Flooring Work">Flooring Work</option>
                  <option value="Fuji Drain Laying">Fuji Drain Laying</option>
                  <option value="Furniture Work">Furniture Work</option> 
                  <option value="Grouting Work">Grouting Work</option>
                  <option value="Ips Work">Ips Work</option>
                  <option value="Misc. Work">Misc. Work</option>
                  <option value="Painting Work">Painting Work</option>
                  <option value="Pile Breaking ">Pile Breaking </option>
                  <option value="Pile Testing Work">Pile Testing Work</option>
                  <option value="Piling Works">Piling Works</option>
                  <option value="Plumbing Work">Plumbing Work</option>
                  <option value="Rebar-Core Cutting Work">Rebar-Core Cutting Work</option>
                  <option value="Rolling Shuttering">Rolling Shuttering</option>
                  <option value="Scaffolder">Scaffolder</option>
                  <option value="Shuttering & Steel Work/Pile Breaking">Shuttering & Steel Work/Pile Breaking</option>
                  <option value="Shuttering Work, B.W">Shuttering Work, B.W</option>
                  <option value="Steel Work">Steel Work</option>
                  <option value="Tile Work">Tile Work</option>
                  <option value="Waterproofing Work">Waterproofing Work</option>
                  <option value="Acid Tiles">Acid Tiles</option>
                  <option value="Painting">Painting</option>
                  <option value="Flooring">Flooring</option>
                  <option value="Jcb and Tractor">Jcb and Tractor</option>
                  <option value="JCB and Cook">JCB and Cook</option>
                  <option value="Masnory and Plaster">Masnory and Plaster</option> 
                  <option value="Other">Other</option>
                </Form.Select>  
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code1:</Col><Col md={6}>   
                    <Form.Select id="sitename" name="sitename" value={inputValues[index]?.sitename || item.site} onChange={(e) => handleinputchange(e, index)}>
                <option value='-'>Nill</option>
                                  {siteOptions.map((option, idx) => (
                                    <option key={idx} value={option.value}>{option.label}</option>
                                  ))}
                                </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code2:</Col><Col md={6}>  
                <Form.Select id="sitename1" name="sitename1" value={inputValues[index]?.sitename1 || item.site1} onChange={(e) => handleinputchange(e, index)}>
                <option value='-'>Nill</option>
                {siteOptions.map((option, idx) => (
                                    <option key={idx} value={option.value}>{option.label}</option>
                                  ))}
                  </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code3:</Col><Col md={6}> 
                <Form.Select id="sitename2" name="sitename2"  value={inputValues[index]?.sitename2 || item.site2} onChange={(e) => handleinputchange(e, index)}>
                <option value='-'>Nill</option>
                {siteOptions.map((option, idx) => (
                                    <option key={idx} value={option.value}>{option.label}</option>
                                  ))}
                  </Form.Select> 
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code4:</Col><Col md={6}> 
                <Form.Select id="sitename3" name="sitename3"  value={inputValues[index]?.sitename3 || item.site3} onChange={(e) => handleinputchange(e, index)}> 
                <option value='-'>Nill</option>
                {siteOptions.map((option, idx) => (
                                    <option key={idx} value={option.value}>{option.label}</option>
                                  ))}
                  </Form.Select>  
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contact Person:</Col><Col md={6}>
                <Form.Control placeholder="Enter Contact Person Name" type="text" id="contactperson" name="contactperson" value={inputValues[index]?.contactperson || item.contact_person} required onChange={(e) => handleinputchange(e, index)}/> 
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contact No:</Col><Col md={6}>
                <Form.Control placeholder="Enter ContactNo" type="text" id="agencycontactno" name="agencycontactno" value={inputValues[index]?.agencycontactno || item.contact_no} required onChange={(e) => handleinputchange(e, index)}/>   </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type" type="text" id="type" name="type" value={inputValues[index]?.type || item.type} required onChange={(e) => handleinputchange(e, index)}/>   </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Address:</Col><Col md={6}>
                <Form.Control placeholder="Enter Address" type="text" id="agencyadd" name="agencyadd" value={inputValues[index]?.agencyadd || item.agency_add} required onChange={(e) => handleinputchange(e, index)}/>   
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>GSTIN No:</Col><Col md={6}>
                <Form.Control placeholder="Enter GSTIN" type="text" id="gst" name="gst" value={inputValues[index]?.gst || item.gst} required onChange={(e) => handleinputchange(e, index)}/>  
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>PAN No:</Col><Col md={6}>
                <Form.Control placeholder="Enter PAN No" type="text" id="pan" name="pan" value={inputValues[index]?.pan || item.pan} required onChange={(e) => handleinputchange(e, index)}/>   
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Bank Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter Bank Name" type="text" id="bankname" name="bankname" value={inputValues[index]?.bankname || item.bank_name} required onChange={(e) => handleinputchange(e, index)}/>   </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Account Number:</Col><Col md={6}>
                <Form.Control placeholder="Enter Account Number" type="text" id="accountno" name="accountno" value={inputValues[index]?.accountno || item.account_number} required onChange={(e) => handleinputchange(e, index)}/>   
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>IFSC Code</Col><Col md={6}>
                <Form.Control placeholder="Enter IFSC Code" type="text" id="ifscno" name="ifscno" value={inputValues[index]?.ifscno || item.ifsc_code} required onChange={(e) => handleinputchange(e, index)}/>   </Col>
                </Row>
                 <br/>
                        <center>
                          <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: '50px', borderRadius: '0px 0px 15px 15px', border: '1.5px solid white' }}>Update Contractor</Button>
                        </center>
                      </div>
                    </form>
            
               <div className="modal-footer"> 
               <p>Note:Date Must Be In DD-MM-YYYY Format&#160;&#160;
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button></p>
               </div>
                  </div> 
         </div>
         </div> 
                </td>
                <td>{index+1}</td>
                <td>{`${item.agency_code}`}</td>
                <td>{`${item.contractor_name}`}</td>
                <td>{`${item.category}`}</td>
                <td>{`${item.site}`}</td>
                <td>{`${item.site1}`}</td>
                <td>{`${item.site2}`}</td>
                <td>{`${item.site3}`}</td>
                <td>{`${item.contact_person}`}</td>
                <td>{`${item.contact_no}`}</td>
                <td>{`${item.type}`}</td>
                <td>{`${item.agency_add}`}</td>
                <td>{`${item.gst}`}</td>
                <td>{`${item.pan}`}</td>
                <td>{`${item.bank_name}`}</td>
                <td>{`${item.account_number}`}</td>
                <td>{`${item.ifsc_code}`}</td>
                </tr></tbody>))}
          </Table> ) : (<div> <center><p>No Result</p></center><br/>
            <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  <th>Sr No.</th>
                    <th>Contractor Code</th>
                    <th>Contractor Name </th>
                    <th>Category</th>
                    <th>Project1</th>
                    <th>Project2</th>
                    <th>Project3</th>
                    <th>Project4</th>
                    <th>Contact Person</th>
                    <th>Mobile No</th> 
                    <th>Type</th> 
                    <th>Address</th> 
                    <th>GST</th> 
                    <th>PAN</th> 
                    <th>Bank</th> 
                    <th>Account Number</th> 
                    <th>ISFC Code</th>
                  </tr>
                </thead>
                {data.map((item1, index1) =>  ( <tbody><tr key={index1}>  
                  <td>
                  <form action="https://erp.smpinfra.in/smp/admin/deleteagency.php" method="POST">
                  <Form.Control type="text" id="ageid" name="ageid" value={`${item1.agency_id}`} hidden/> 
                <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                </form>
                </td>
                <td> 
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#editmodal${index1}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`editmodal${index1}`} tabIndex="-1" aria-labelledby={`editmodalLabel${index1}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModal1Label">Update Contractor</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <form action="https://erp.smpinfra.in/smp/admin/updateagency.php" method="POST"> 
               <Row align="left" style={{padding:'10px'}}>
               <Form.Control type="text" id="ageid" name="ageid" value={`${item1.agency_id}`} hidden/> 
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Code:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Contractor Code" type="text" id="agencycode" name="agencycode" defaultValue={item1.agency_code}/>
                </Col>
                </Row> 
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contractor Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Contractor Name" type="text" id="agencyname" name="agencyname" defaultValue={item1.contractor_name}/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Catagory Name:</Col><Col md={6}> 
                <Form.Select id="catname" name="catname" defaultValue={item1.category}>v
                  <option value="Labour Supply">Labour Supply</option>
                  <option value="Shuttering & Steel Work">Shuttering & Steel Work</option>
                  <option value="Shuttering Work">Shuttering Work </option>
                  <option value="Reinforcement Work">Reinforcement Work </option>
                  <option value="B.W & Plaster Work">B.W & Plaster Work</option>
                  <option value="Allu. Windows Fitting">Allu. Windows Fitting</option>
                  <option value="Anti Termmite Work">Anti Termmite Work</option>
                  <option value="Bitumen Carpet">Bitumen Carpet</option>
                  <option value="Coupler Threding">Coupler Threding</option>
                  <option value="Excavation & Dismentalling  Work">Excavation & Dismentalling  Work</option>
                  <option value="Excavation Work">Excavation Work</option>
                  <option value="Fabrication Work">Fabrication Work</option>
                  <option value="Flooring Work">Flooring Work</option>
                  <option value="Fuji Drain Laying">Fuji Drain Laying</option>
                  <option value="Furniture Work">Furniture Work</option> 
                  <option value="Grouting Work">Grouting Work</option>
                  <option value="Ips Work">Ips Work</option>
                  <option value="Misc. Work">Misc. Work</option>
                  <option value="Painting Work">Painting Work</option>
                  <option value="Pile Breaking ">Pile Breaking </option>
                  <option value="Pile Testing Work">Pile Testing Work</option>
                  <option value="Piling Works">Piling Works</option>
                  <option value="Plumbing Work">Plumbing Work</option>
                  <option value="Rebar-Core Cutting Work">Rebar-Core Cutting Work</option>
                  <option value="Rolling Shuttering">Rolling Shuttering</option>
                  <option value="Scaffolder">Scaffolder</option>
                  <option value="Shuttering & Steel Work/Pile Breaking">Shuttering & Steel Work/Pile Breaking</option>
                  <option value="Shuttering Work, B.W">Shuttering Work, B.W</option>
                  <option value="Steel Work">Steel Work</option>
                  <option value="Tile Work">Tile Work</option>
                  <option value="Waterproofing Work">Waterproofing Work</option>
                  <option value="Acid Tiles">Acid Tiles</option>
                  <option value="Painting">Painting</option>
                  <option value="Flooring">Flooring</option>
                  <option value="Jcb and Tractor">Jcb and Tractor</option>
                  <option value="JCB and Cook">JCB and Cook</option>
                  <option value="Masnory and Plaster">Masnory and Plaster</option> 
                  <option value="Other">Other</option>
                </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code1:</Col><Col md={6}> 
                <Form.Select id="sitename" name="sitename" defaultValue={item1.site}> 
                <option value='-'>Nill</option>
                    {siteOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code2:</Col><Col md={6}>   
                <Form.Select id="sitename1" name="sitename1" defaultValue={item1.site1}> 
                <option value='-'>Nill</option>
                    {siteOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code3:</Col><Col md={6}> 
                <Form.Select id="sitename2" name="sitename2" defaultValue={item1.site2}>
                <option value='-'>Nill</option>
                    {siteOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select> 
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code4:</Col><Col md={6}>   
                <Form.Select id="sitename3" name="sitename3" defaultValue={item1.site3}>
                <option value='-'>Nill</option>
                    {siteOptions.map((option, index) => (
                      <option key={index} value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contact Person:</Col><Col md={6}>
                <Form.Control placeholder="Enter Contact Person Name" type="text" id="coperson" name="contactperson" defaultValue={item1.contact_person}/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Contact No:</Col><Col md={6}>
                <Form.Control placeholder="Enter ContactNo" type="text" id="agencycontactno" name="agencycontactno" defaultValue={item1.contact_no}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Type:</Col><Col md={6}>
                <Form.Control placeholder="Enter Type" type="text" id="type" name="type" defaultValue={item1.type}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Address:</Col><Col md={6}>
                <Form.Control placeholder="Enter Address" type="text" id="agencyadd" name="agencyadd" defaultValue={item1.agency_add}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>GSTIN No:</Col><Col md={6}>
                <Form.Control placeholder="Enter GSTIN" type="text" id="gst" name="gst" defaultValue={item1.gst}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>PAN No:</Col><Col md={6}>
                <Form.Control placeholder="Enter PAN No" type="text" id="pan" name="pan" defaultValue={item1.pan}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Bank Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter Bank Name" type="text" id="bankname" name="bankname" defaultValue={item1.bank_name}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Account Number:</Col><Col md={6}>
                <Form.Control placeholder="Enter Account Number" type="text" id="accountno" name="accountno"  defaultValue={item1.account_number}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>IFSC Code</Col><Col md={6}>
                <Form.Control placeholder="Enter IFSC Code" type="text" id="ifscno" name="ifscno" defaultValue={item1.ifsc_code}/></Col>
                </Row>
                 <br/>
               <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}}>Update Contractor</Button></center>
               </form></div>
               <div className="modal-footer"> 
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>
                </td>
                <td>{index1+1}</td>
                <td>{`${item1.agency_code}`}</td>
                <td>{`${item1.contractor_name}`}</td>
                <td>{`${item1.category}`}</td>
                <td>{`${item1.site}`}</td>
                <td>{`${item1.site1}`}</td>
                <td>{`${item1.site2}`}</td>
                <td>{`${item1.site3}`}</td>
                <td>{`${item1.contact_person}`}</td>
                <td>{`${item1.contact_no}`}</td>
                <td>{`${item1.type}`}</td>
                <td>{`${item1.agency_add}`}</td>
                <td>{`${item1.gst}`}</td>
                <td>{`${item1.pan}`}</td>
                <td>{`${item1.bank_name}`}</td>
                <td>{`${item1.account_number}`}</td>
                <td>{`${item1.ifsc_code}`}</td>
                </tr></tbody>
                ))}
                </Table></div>
             )}<br/>
        </Container> 
         
      );
}
export default Agency;
