 
import React from 'react'; 
import Sidebar from "./Sidebar";  
function App() {
  return (
    <div className="App"> 
 <Sidebar/> 
    </div>
  );
}

export default App;
