import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';  
 
function Manday_report_user() { 
  const [data, setData] = useState([]);  
  const [defaultOption, setDefaultOption] = useState(''); 
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  useEffect(()=>{
    var username = sessionStorage.getItem("username"); 
    var table = "<option value=''>Select Site</option>"; 
    table+="<option value='"+username+"'>"+username+"</option>";
	 document.getElementById('siteid').innerHTML=table;
  },[]);  
  const fetchagency = async () => {
    var sitecode = $('#siteid').val();   
    try {
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/agesel_mandays.php",data:{sitecode:sitecode},success(data){ 
        var jsonData1 = JSON.parse(data);  
        var table = "<option value=''>Select Agency</option>";
        for(let i=0;i<jsonData1.length;i++){
            if(jsonData1[i].status !== 0){
            table+="<option value='"+jsonData1[i].agencycode+"'>"+jsonData1[i].agencyname+"</option>";
            }
        } 
        document.getElementById("ageid").innerHTML = table; 
        try{
        var qstring = window.location.search;
        var params = new URLSearchParams(qstring);
        var id = params.get('did');
        }
        catch(e){
            id = '';
        }
        $('#ageid').val(id); 
        setData(jsonData1);
    }}); 
  }
  catch (error) {
    console.error('Error fetching data:', error);
  }  
}  
const logout=()=>{
    setIsLoggedIn(false);
		sessionStorage.removeItem("logstatus");
		window.location.reload();
	}
   const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 180 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  };
  const redirect = (event) =>
    {
      var site = $("#siteid").val();
      var agencycode = $("#ageid").val(); 
      var date = $("#datetime").val();
      window.location.href = "https://erp.smpinfra.in/smp/admin/manday_ot_report.php?site="+site+"&agencycode="+agencycode+"&date="+date;
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
return ( 
<div><Container style={{ color: "#565656", padding: '0', backgroundColor: 'white', maxWidth: '80rem', borderRadius: '15px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)' }} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
   <br />
   <h2 align="center">Mandays And OT Report</h2>
   <hr />
   <Row align="left" style={{ padding: '10px' }}>
   <Col md={4}>
       <Form.Select id="siteid" onChange={fetchagency} >
       </Form.Select> </Col>  
       <Col md={4}>
       <Form.Select id="ageid">
       </Form.Select> </Col>  
       <Col md={4}>
       <Form.Control id="datetime" type="month"/> </Col>   
   </Row>  
       <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '100%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={redirect}>Print Report</Button>
 </Container>
 </div>
);
}

export default Manday_report_user;