import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js"; 
import swal from 'sweetalert'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faEdit, faTrashRestoreAlt } from '@fortawesome/free-solid-svg-icons';

const Site = () =>{
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);

  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  const handleInputChange = (event) => {
      setQuery(event.target.value);
      fetchSearchResults(event.target.value);
  };

  const fetchSearchResults = (searchQuery) => {
      fetch(`https://erp.smpinfra.in/smp/admin/filter_site.php?query=${searchQuery}`)
          .then(response => response.json())
          .then(data => {
              setResults(data);
          })
          .catch(error => {
              console.error('Error fetching search results:', error);
          });
  };
 
  useEffect(()=>{ 
    $('document').ready(function(){
      var date = new Date();
  
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      
      var today = year + "-" + month + "-" + day;    
      $("#datecreated").attr("value", today);
      }); 
    try {
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/select_site_all.php" ,success(data){
        var jsonData = JSON.parse(data); 
        document.cookie = jsonData.length; 
        setData(jsonData);
      }});
    }
    catch (error) {
      console.error('Error fetching data:', error);
    }  
  }); 
 /* const site_add=(event)=>{ 
    var sitename = $('#sitename1').val();
		var siteadd = $('#siteadd1').val(); 
    var datecreated = $('#datecreated1').val();  
    var sitepart = $('#sitepart1').val();
    var sitecode = $('#sitecode1').val();
    var sitecost = $('#sitecost1').val();
   try{
    if(sitename==null || sitename==""){
      swal({title:"Error!",text:"Project Name Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#sitename').focus();
      });
    }
    else if(siteadd==null || siteadd==""){
      swal({title:"Error!",text:"Project Location Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#siteadd').focus();
      });
    }
    else if(datecreated==null || datecreated==""){
      swal({title:"Error!",text:"Date Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#datecreated').focus();
      });
    }
    else if(sitecode==null || sitecode==""){
      swal({title:"Error!",text:"Project Code Can Not Be Blank!",icon:"error",buttons:false,timer:2000}).then(()=>{
        $('#sitecode').focus();
      });
    }
    else{
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/site_add.php",data:{sitename:sitename,siteadd:siteadd,datecreated:datecreated,sitepart:sitepart,sitecode:sitecode,sitecost:sitecost},success(data){
        if(data == 1){
          swal({title:"Successful!",text:"New Project Added Successfully",icon:"success",buttons:false,timer:2000}).then(()=>{
            window.location.reload();
          });
          }
          else{
            swal({title:"Error!",text:"Project Already Exists",icon:"error",buttons:false,timer:2000}).then(()=>{
              window.location.reload();
            });
          }
      }});}}
      catch(e){
        alert(e);
      }
  }  */  
  const export_site = (event) =>{
   window.location.href = "https://erp.smpinfra.in/smp/admin/sample_export_site.php";
  }
  const exportmaster = (event) =>{
    window.location.href = "https://erp.smpinfra.in/smp/admin/export_site_master.php";
  }
  const logout=()=>{
    setIsLoggedIn(false);
    sessionStorage.removeItem("logstatus");
    window.location.reload();
  }
   const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 180 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  };
    return ( 
        <Container style={{color: "#565656",padding:'0',backgroundColor:'white',maxWidth:'78rem',borderRadius:'15px',boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)',marginTop:'-45px'}} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>  <br/>
                <h2 align="center">Add Project</h2> <hr/>
                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} data-bs-toggle="modal" data-bs-target="#exampleModal">Import File</Button>    
         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModalLabel">Import File</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <form method="POST" action="https://erp.smpinfra.in/smp/admin/siteimport.php" encType="multipart/form-data">
               <Row align="left">
              <Col md={6}>
                <Form.Control type="file" name="import_file" id="import_file"/>
                </Col>
                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '15%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}name="save_excel_data">Import File</Button> 
                <Button type="button" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '15%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}name="save_excel_data" onClick={exportmaster}>Export Project Master</Button>
                <Button type="button" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '15%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }} onClick={export_site}>Export Sample File</Button>
                </Row></form> <br/> 
                 
               <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Project Name</th>
                    <th>Parts</th>
                    <th>Project Code</th>
                    <th>Project Location</th>
                    <th>Start Date</th>
                    <th>Total Cost</th>  
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>GCL-PANOLI PEI</td>
                    <td>PEI</td>
                    <td>GCL-PAN-PEI</td>
                    <td>bharuch</td>
                    <td>2024-02-28</td>
                    <td>300000</td> 
                  </tr>
                </tbody>
          </Table>
               </div>
               <div className="modal-footer">
                <p>Note : Date Must Be In DD-MM-YYYY Format&#160;&#160;</p>
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>

                <Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '50%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}data-bs-toggle="modal" data-bs-target="#exampleModal1">Fill Form</Button>
                <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="exampleModal1Label">Add New Project</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <Form method="POST" action="https://erp.smpinfra.in/smp/admin/site_add.php">
               <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Project Name" type="text" id="sitename" name="sitename" required/>
                </Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Parts:</Col><Col md={6}><Form.Control placeholder="Enter Project Parts" type="text" id="sitepart" name="sitepart"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code:</Col><Col md={6}><Form.Control placeholder="Enter Project Code" type="text" id="sitecode" name="sitecode" required/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Location:</Col><Col md={6}><Form.Control placeholder="Enter Project Location" type="text" id="siteadd" name="siteadd" /></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Start Date:</Col><Col md={6}><Form.Control type="date" id="datecreated" name="datecreated"/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Total Cost:</Col><Col md={6}><Form.Control placeholder="Enter Total Cost" type="text" id="sitecost" name="sitecost" /></Col>
                </Row>
                <br/>
               <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}}>Add Project</Button></center></Form>
               </div>
               <div className="modal-footer"> 
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button>
               </div>
            </div>
         </div>
         </div>
                <br/><br/>
                <Row align="left" style={{padding:'10px'}}> <center>
                <Col md={11}>
                <Form.Control type="text"value={query}
                onChange={handleInputChange}
                placeholder="Search by name,code,parts,location,start-date or total cost" /></Col></center></Row><br/>
                 {results.length > 0 ? (
                <Table striped bordered hover responsive>
                <thead>
                  <tr>
                  <th></th>
                  <th></th>
                    <th>Sr No.</th>
                    <th>Project Name</th>
                    <th>Parts</th>
                    <th>Project Code</th>
                    <th>Project Location</th>
                    <th>Start Date</th>
                    <th>Total Cost</th>
                    <th>Status</th> 
                  </tr>
                </thead>
                {results.map((item1, index1) =>  ( <tbody><tr key={index1}> 
                  <td>
                  <form action="https://erp.smpinfra.in/smp/admin/deletesite.php" method="POST">
                  <Form.Control type="text" id="siteid" name="siteid" value={`${item1.site_id}`} hidden/> 
                <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                </form>
                </td>
                <td>  
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#search${item1.site_code}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`search${item1.site_code}`} tabIndex="-1" aria-labelledby={`searchLabel${item1.site_code}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="searchLabel">Update Project</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div><form action="https://erp.smpinfra.in/smp/admin/updatesite.php" method="POST">
               <div className="modal-body">   
               <Form.Control type="text" id="siteid" name="siteid" value={item1.site_id} required hidden/>
               <Row align="left" style={{padding:'10px'}}>
                    <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Name:</Col><Col md={6}><Form.Control placeholder="Enter Project Name" type="text" id="sitename" name="sitename" defaultValue={item1.site_name} required/></Col>
                    </Row>
                    <Row align="left" style={{padding:'10px'}}>
                    <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Parts:</Col><Col md={6}><Form.Control placeholder="Enter Project Parts" type="text" id="sitepart" name="sitepart" defaultValue={item1.parts} required/></Col>
                    </Row>
                    <Row align="left" style={{padding:'10px'}}>
                    <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code:</Col><Col md={6}><Form.Control placeholder="Enter Project Code" type="text" id="sitecode" name="sitecode" defaultValue={item1.site_code} required/></Col>
                    </Row>
                    <Row align="left" style={{padding:'10px'}}>
                    <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Location:</Col><Col md={6}><Form.Control placeholder="Enter Project Location" type="text" id="siteadd" name="siteadd" defaultValue={`${item1.site_location}`}/></Col>
                    </Row>
                    <Row align="left" style={{padding:'10px'}}>
                    <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Start Date:</Col><Col md={6}><Form.Control placeholder="Enter Date" type="text" id="datecreated" name="datecreated" defaultValue={`${item1.start_date}`}/></Col>
                    </Row>
                    <Row align="left" style={{padding:'10px'}}>
                    <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Total Cost:</Col><Col md={6}><Form.Control placeholder="Enter Total Cost" type="text" id="sitecost" name="sitecost" defaultValue={`${item1.total_cost}`}/></Col>
                    </Row>
                    <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Site Status:</Col><Col md={6}>
                <Form.Select id="sitestatus" name="sitestatus" defaultValue={item1.site_status} >  
                <option hidden>Select Status</option> 
                <option value="0">Working</option> 
                <option value="1">Inactive</option>
			          </Form.Select> </Col>
                </Row>
                    <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >Update Project</Button></center> 
               </div></form>
               <div className="modal-footer"> 
               <p>Note:Date Must Be In DD-MM-YYYY Format&#160;&#160;
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button></p>
               </div>
                  </div> 
         </div>
         </div> 
                </td>
                <td>{index1+1}</td>
                <td>{`${item1.site_name}`}</td>
                <td>{`${item1.parts}`}</td>
                <td>{`${item1.site_code}`}</td>
                <td>{`${item1.site_location}`}</td>
                <td>{`${item1.start_date}`}</td>
                <td>{`${item1.total_cost}`}</td>  
                <td> {item1.site_status === '1' ? 'Inactive' : item1.site_status === '0' ? 'Working' : ''}</td>  
                </tr></tbody>))}
          </Table> ) : (<div>
           <center><p>No Result</p></center><br/>
                   <Table striped bordered hover responsive>
                   <thead>
                     <tr>
                     <th></th>
                     <th></th>
                       <th>Sr No.</th>
                       <th>Project Name</th>
                       <th>Parts</th>
                       <th>Project Code</th>
                       <th>Project Location</th>
                       <th>Start Date</th>
                       <th>Total Cost</th>
                       <th>Status</th>
                     </tr>
                   </thead>
                   {data.map((item, index) =>  ( <tbody><tr key={index}> 
                    <td>
                  <form action="https://erp.smpinfra.in/smp/admin/deletesite.php" method="POST">
                  <Form.Control type="text" id="siteid" name="siteid" value={`${item.site_id}`} hidden/> 
                <Button type="submit" variant="primary" style={{backgroundColor:'red',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >  <FontAwesomeIcon icon={faTrash} /> </Button> 
                </form>
                </td>
                <td> 
                <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'100%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} data-bs-toggle="modal" data-bs-target={`#editmodal${index}`}>  <FontAwesomeIcon icon={faEdit} /> </Button> 
                <div className="modal fade" id={`editmodal${index}`} tabIndex="-1" aria-labelledby={`editmodalLabel${index}`} aria-hidden="true">
         <div className="modal-dialog"style={{maxWidth:'78rem'}}>
            <div className="modal-content">
               <div className="modal-header">
               <h5 className="modal-title text-primary" id="editModal1Label">Update Project</h5>
               <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div className="modal-body"> 
               <Form action="https://erp.smpinfra.in/smp/admin/updatesite.php" method="POST"> 
               <Row align="left" style={{padding:'10px'}}>
               <Form.Control type="text" id="siteid" name="siteid" value={`${item.site_id}`} hidden/>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Name:</Col><Col md={6}>
                <Form.Control placeholder="Enter New Project Name" type="text" id="sitename" name="sitename" defaultValue={item.site_name} required/>
                </Col>
                </Row> 
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Parts:</Col><Col md={6}><Form.Control placeholder="Enter Project Parts" type="text" id="sitepart" name="sitepart" defaultValue={item.parts} required/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Code:</Col><Col md={6}><Form.Control placeholder="Enter Project Code" type="text" id="sitecode" name="sitecode" defaultValue={item.site_code} required/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Project Location:</Col><Col md={6}><Form.Control placeholder="Enter Project Location" type="text" id="siteadd" name="siteadd" defaultValue={item.site_location}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Start Date:</Col><Col md={6}><Form.Control placeholder="Enter Date" type="text" id="datecreated" name="datecreated" defaultValue={item.start_date}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Total Cost:</Col><Col md={6}><Form.Control placeholder="Enter Total Cost" type="text" id="sitecost" name="sitecost" defaultValue={item.total_cost}/></Col>
                </Row>
                <Row align="left" style={{padding:'10px'}}>
                <Col md={1}></Col><Col md={3} style={{verticalAlign:"sub"}}>Site Status:</Col><Col md={6}> 
                <Form.Select id="sitestatus" name="sitestatus" value={item.site_status}>  
                <option hidden>Select Status</option> 
                <option value="0">Working</option> 
                <option value="1">Inactive</option>
			          </Form.Select> </Col>
                </Row>
                <center> <Button type="submit" variant="primary" style={{backgroundColor:'rgb(0, 189, 252)',width:'50%',height:"50px",borderRadius:"0px 0px 15px 15px",border:'1.5px solid white'}} >Update Project</Button></center>
                </Form> </div>
               <div className="modal-footer"> 
               <p>Note:Date Must Be In DD-MM-YYYY Format&#160;&#160;
               <button type="button" className="btn btn-warning" data-bs-dismiss="modal">Close</button></p>
               </div>
                  </div> 
         </div>
         </div> 
                </td>
                <td>{index+1}</td>
                <td>{`${item.site_name}`}</td>
                <td>{`${item.parts}`}</td>
                <td>{`${item.site_code}`}</td>
                <td>{`${item.site_location}`}</td>
                <td>{`${item.start_date}`}</td>
                <td>{`${item.total_cost}`}</td>  
                <td> {item.site_status === '1' ? 'Inactive' : item.site_status === '0' ? 'Working' : ''}</td>
                </tr></tbody>))}
                   </Table>
                   </div>
                )} 
        </Container> 
         
      );
}
export default Site;