import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {useState,useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import swal from 'sweetalert';  
 
function Supply_mandays_user() { 
  const [datamain, setDatamain] = useState([]);
  const [datadate, setDatadate] = useState([]);
  const [data, setData] = useState([]);  
  const [defaultOption, setDefaultOption] = useState(''); 
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const [logoutTimer, setLogoutTimer] = useState(null);
  useEffect(()=>{
    $.ajax({
      type: "POST",
      url: "https://erp.smpinfra.in/smp/admin/checkdate.php",
      success: function(data) {
        try {
          var Data1 = JSON.parse(data);  
          setDatadate(Data1); 
        } catch(error) {
          console.error("Error parsing JSON:");
        }
      } 
    });
    var snameses = sessionStorage.getItem("sitename"); 
    var table = "<option value=''>Select Site</option>"; 
    table+="<option value='"+snameses+"'>"+snameses+"</option>";
	 document.getElementById('siteid').innerHTML=table;
  },[]);  
  const fetchagency = async () => {
    var sitecode = $('#siteid').val();  
    $.ajax({
      type: "POST",
      url: "https://erp.smpinfra.in/smp/admin/manmatch.php",
      data:{sitecode:sitecode},
      success: function(data) {
        try {
          var Data2 = JSON.parse(data);  
          setDatamain(Data2); 
         // console.log(Data2);
        } catch(error) {
          console.error("Error parsing JSON:");
        }
      } 
    });  
    try {
      $.ajax({type:"POST",url:"https://erp.smpinfra.in/smp/admin/agesel_mandays.php",data:{sitecode:sitecode},success(data){ 
        var jsonData1 = JSON.parse(data);   
        setData(jsonData1);
    }}); 
  }
  catch (error) {
    console.error('Error fetching data:', error);
  }  
}  
const logout=()=>{
    setIsLoggedIn(false);
		sessionStorage.removeItem("logstatus");
		window.location.reload();
	}
   const handleActivity = () => {
    // Reset the logout timer on user activity
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
    const timer = setTimeout(logout, 180 * 60 * 1000); // Reset timer to 3 minutes
    setLogoutTimer(timer);
  };
  let date = [];
  const redirect = (agencyCode, agencyName, siteCode) => { 
    var encodedAgencyname  = encodeURIComponent(agencyName);
    window.location.href = `https://erp.smpinfra.in/supply_mandays_2?agencycode=${agencyCode}&agencyname=${encodedAgencyname}&sitecode=${siteCode}`;
  };
  
return ( 
<div><Container style={{ color: "#565656", padding: '0', backgroundColor: 'white', maxWidth: '80rem', borderRadius: '15px', boxShadow: '0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)' }} fluid onMouseMove={handleActivity} onClick={handleActivity} onKeyDown={handleActivity}>
   <br />
   <h2 align="center">Mandays And OT</h2>
   <hr />
   <Row align="left" style={{ padding: '10px' }}>
   <Col md={12}>
       <Form.Select id="siteid" onChange={fetchagency}>
       </Form.Select> </Col>  
   </Row> 
  
   <Table striped bordered hover responsive> 
     <thead style={{ position:'sticky', zIndex: '1' }}> 
     <tr> 
      <th>Sr No</th>
       <th>Contractor Code</th>
       <th>Contractor Name</th>
       {datadate.map((item2, index2) => { 
                  date.push(item2); 
                  return (
                  <th key={index2}>{item2.split('-')[0]}</th>)
                 })}
                 <th>Add New Record</th>
     </tr> 
     </thead>
     <tbody>
     {data.map((agency, idx) => (
  <tr key={idx}>
    <td>{idx + 1}</td>
    <td>{agency.agencycode}</td>
    <td>{agency.agencyname}</td>
    {date.map((dateItem1, index4) => {
      const parts = dateItem1.split('-');
      const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
      console.log('Checking date:', formattedDate);
      const isDateMatch = datamain.some(data => data.date_reported === formattedDate);
      const isSiteMatch = datamain.some(data => data.agencyname === agency.agencyname);
      console.log('isDateMatch:', isDateMatch, 'isSiteMatch:', isSiteMatch);
      return (
        <td key={index4}>
          {isDateMatch && isSiteMatch ? '✔' : '✘'}
        </td>
      );
    })}
        <td hidden> <Form.Control id={`code${idx}`} type="text" value={agency.agencycode}/></td>
                   <td><Button type="submit" variant="primary" style={{ backgroundColor: 'rgb(0, 189, 252)', width: '100%', height: "50px", borderRadius: "0px 0px 15px 15px", border: '1.5px solid white' }}  onClick={() => redirect(agency.agencycode, agency.agencyname, agency.sitecode)}>Add Record</Button></td>
                 </tr>
                ))} 
     </tbody>
       </Table> 
       
 </Container>
 </div>
);
}

export default Supply_mandays_user;